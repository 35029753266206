.About-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(46,47,48, 0.5);
}
.About {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.About .closeBtn {
  position: absolute;
  right: 1.95rem;
  top: 1.25rem;
  height: 0.875rem;
  width: 0.875rem;
  cursor: pointer;
  background: none;
  color: none;
  outline: none;
  border: none;
}
.About-inner {
  text-align: left;
  max-width: 30rem;
  background-color: #194670;
  color: #f6f7f8;
  overflow-y: auto;
  max-height: calc(100% - 3rem);
  width: calc(100% - 3rem);
  box-shadow: 0px 0.25rem 0.25rem rgba(46, 47, 48, 0.1);
  border-radius: 0.25rem;
}
.About h1 {
  font-size: 1.5rem;
  line-height: 1.6875rem;
}
.About-header {
  position: relative;
}
.About-body {
  padding: 1.0625rem 1.5625rem;
}

.About .accordion__heading > div {
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 0.5rem;
  margin-left: -0.5rem;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.About .accordion__heading > div:after {
  content: "";
  display: flex;
  height: 0.5rem;
  width: 0.75rem;
  background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 12 8' fill='%23f6f7f8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.00003L1.41 7.41003L6 2.83003L10.59 7.41003L12 6.00003L6 3.43323e-05L0 6.00003Z' fill='%23f6f7f8'/%3E%3C/svg%3E%0A");
}
.About .accordion__heading > div:focus {
  outline: none;
  text-decoration: underline;
}
.About .accordion__heading > div[aria-expanded="true"]:after {
  transform: rotate(180deg);
}
