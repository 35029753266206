.Level {
  display: flex;
  flex-flow: column;
}

.Level .Card {
  padding: 0.5rem;
}

.Level .levelPicture {
  display: block;
  align-self: center;
  justify-self: center;
  height:  50vw;
}
@media only screen and (min-width: 620px) {
  .Level .levelPicture {
    height: 18.1245rem;
  }
}
.Level .levelImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.1s ease-in-out;
}
.Level .loading .levelImg {
  opacity: 0.5;
}
.Level .makerPicture {
  align-self: center;
  justify-self: center;
  height: 6.125rem;
  width: 6.125rem;
}
.Level .makerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}
.Level .loading .makerImg {
  opacity: 0.5;
}
.Level .makerDescription {
  padding-top: 1rem;
  white-space: pre-line;
}

.Level .makerCard {
  display: flex;
  flex-flow: column;
}

.Level .levelInfo {
  display: flex;
}
.Level .levelName {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.Level .makerInfo {
  display: flex;
  flex: 1 0 0;
  padding-bottom: 0.5rem;
  text-align: left;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Level .makerName {
  font-size: 1rem;
  flex: 1 0 0;
  margin-top: 0.5rem;
}
.Level .makerId {
  font-size: 0.5rem;
  font-family: "latoregular", Arial, Helvetica, sans-serif;
  display: block;
}
.Level .nationality {
  margin-right: 0.45rem;
}
.Level .levelCode {
  font-size: 1rem;
  background: #af044a;
  font-family: "latoregular", Arial, Helvetica, sans-serif;
  font-weight: 900;
  border-radius: 0px 0px 0.25rem 0.25rem;
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
}

.Level .info {
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-flow: column;
  width: 100%;
}
.Level .levelInfo {
  display: flex;
}

.Level .difficulty {
  font-size: 0.75rem;
  align-self: flex-end;
  justify-self: flex-end;
}

.Level .tags {
  align-self: flex-end;
  padding-top: 0.2rem;
  padding-right: 0.2rem;
  flex: 1 0 0;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content;
  grid-gap: 0.3rem;
}

.Level .description {
  white-space: pre-line;
  padding-top: 1rem;
}
.Level .Icon.arrow-left {
  margin: 0;
}
.Level .navigation.hasNextLevel {
  display: flex;
  justify-content: flex-end;
}
.Level .navigation.hasNextLevel.hasPreviousLevel {
  display: flex;
  justify-content: space-between;
}
.Level .navigation {
  display:flex;
  flex-flow: row;
}
.Level .navigation .Button.arrow-right {
  align-self: flex-end;
  justify-self: flex-end;
}
.Level .navigation.hasNextLevel.hasPreviousLevel .Button.arrow-right{
  margin-left:0;
}
.Level .navigation .Button.arrow-left {
  max-width: 5.125rem;
  margin-right:0;
}