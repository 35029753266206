/* RESET DEFAULT HTML STYLING */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html,
#root,
.App {
  width: 100%;
}

a{
  color:#facd00
}
a:active{
  color:#ffea8e
}
#root,
.App {
  position: relative;
  overflow-x: auto;
}
div + h1 {
  margin-top: 2rem;
}

h1 {
  text-align: left;
  padding: 0 0 1rem 0;
  line-height: 1.3rem;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: normal;
}
h2 {
  font-size: 1rem;
  line-height: 1.125rem;
}
p {
  padding-bottom: 0.75rem;
  text-align: left;
  letter-spacing: -0.03rem;
}
p > strong {
  font-weight: bold;
}
p {
  font-size: 0.875rem;
}

.Logo {
  width: 21.875rem;
  max-width: 100%;
  align-self: center;
  justify-self: center;
  padding: 1rem;
}
.Logo > a {
  display: block;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.Logo.small {
  width: 7rem;
  height: 3rem;
  padding: 0;
  align-self: center;
  justify-self: center;
}

.toolbar {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toolbar.small {
  padding: 0 0 2rem 0;
  display: flex;
  flex: 1 0 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toolbar.big {
  justify-content: flex-end;
  height: 13rem;
}

.toolbar .AboutBtn {
  margin:1em 0 0 0;
  height:auto;
}
.toolbar.big .Button-inner {
  font-size: 1rem;
  max-height: 3.375rem;
  width: 5.875rem;
}
.toolbar.big span.info-inverted {
  margin-left: 0.4rem;
}
.toolbar.big .Logo {
  flex: 0 1 auto;
  position: absolute; /* new */
  left: 50%;
  transform: translateX(-50%);
}
.toolbar .info-inverted {
  max-width: 8rem;
  z-index: 99;
}

/* COMPONENTS */
.Card {
  position: relative;
  padding: 1.3125rem;
  margin: 0 0 0.5rem 0;
  width: calc(100vw - 2rem);
  max-width: 30rem;
  background-color: #0c2338;
  min-width: 10rem;
  border-radius: 0.25rem;
  box-shadow: 0px 0.25rem 0.25rem rgba(46, 47, 48, 0.1);
}
.Card-content {
  display: flex;
  flex-flow: column;
}
.Card .Clickable {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: block;
  color: inherit;
  text-decoration: none;
}
.Card .Clickable:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Card.hasIllustration .Illustration {
  float: right;
  width: 6em;
}
.Card.disabled {
  background-color: #194670;
}
@media only screen and (min-width: 620px) {
  .Card {
    min-width: 34.25rem;
  }
  .Button {
    max-width: 15.6875rem;
  }
}

.Button {
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  height: inherit;
  align-items: center;
  width: calc(100% + 1rem);
  justify-content: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background:none;
   /* Clickable area */
  padding:.5rem;
  margin:-.5rem;
}
.Button-inner {
  font-family: "SuperMarioMakerExtended";
  border: none;
  display:flex;
  font-size: 1rem;
  max-height: 3rem;
  flex:1;
  line-height: 1.125rem;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: black;
  background-color: #facd00;
  padding: 0.875rem 0 0.875rem 0;
  box-shadow: 0px 0.25rem 0.25rem rgba(46, 47, 48, 0.1);
  border-radius: 0.25rem;
}
.Button.inverted > .Button-inner {
  color: #f6f7f8;
  background-color: #0c2338;
  padding: 0.5rem 0;
}
.Button.info-inverted{
  width:inherit;
}
.Button.primary > .Button-inner {
  background-color: #af044a;
  color: white;
}
.Button-inner .Icon {
  margin-left: 0.75rem;
  margin-right: -0.25rem;
  height: 1rem;
  width: 1rem;
}
.Button-inner .Icon.folder,
.Button-inner .Icon.play-button {
  height: 1.5rem;
  width: 1.5rem;
}
.Button-inner .Icon.arrow-right {
  height: 1.125rem;
  width: 1.125rem;
}
.Button-inner .Icon.arrow-left {
  height: 1.125rem;
  width: 1.125rem;
}
.Button-inner .Icon.arrow-left-inverted {
  height: 1.125rem;
  width: 1.725rem;
  margin-right: 0;
  margin-left: -1rem;
  padding: 0 0.3rem;
}
.Button-inner .Icon.info-inverted {
  width: 1.5rem;
  height: 1.5rem;
}
.info-inverted {
  width: auto;
}
/* TYPOGRAPHY */

body {
  font-family: "SuperMarioMakerExtended", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.125rem;
  color: #f6f7f8;
}
@media only screen and (min-width: 620px) {
  html,
  body {
    font-size: 18px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tag {
  padding: 0.3rem;
  border-radius: 0.25rem;
  background-color: #256aa8;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}
.stars {
  width: 3.27rem;
  display: inline-block;
}
.star svg {
  width: 0.8125rem;
  height: 0.75rem;
}

@font-face {
  font-family: "SuperMarioMakerExtended";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SuperMarioMakerExtended.eot"); /* IE9 Compat Modes */
  src: local("Super-Mario-Maker-Extended"), local("SuperMarioMakerExtended"),
    url("./fonts/SuperMarioMakerExtended.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SuperMarioMakerExtended.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/SuperMarioMakerExtended.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/SuperMarioMakerExtended.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/SuperMarioMakerExtended.svg#SuperMarioMakerExtended")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "SuperMario";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SuperMario.eot"); /* IE9 Compat Modes */
  src: local("Super-Mario"), local("SuperMario"),
    url("./fonts/SuperMario.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SuperMario.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/SuperMario.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/SuperMario.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/SuperMario.svg#SuperMario")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "latoregular";
  src: url("./fonts/lato-regular.woff2") format("woff2"),
    url("./fonts/lato-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
