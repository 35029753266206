.Credits .Card{
    text-align: left;
}
.Credits .Card h2{
    font-size: 1rem;
    line-height: 1.125rem;
    padding-bottom: 1rem;
}
.Credits .Card dl{
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    display: grid;
    font-size: 1rem;
    line-height: 1.125rem;
    padding-bottom: 2rem;
}
.Credits .Card dl dt{
    font-size: .875rem;
    line-height: 1.125rem;
    font-weight: bold;
    padding-bottom: .3rem;
    padding-right: .3rem;
}
.Credits .Card dl dt + dd {
    grid-column-start: inherit;
    padding-bottom: 0rem;
}
.Credits .Card dl dd{
    display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  
    font-size: 1rem;
    line-height: 1.125rem;
    grid-column-start: 2;
    padding-bottom: .3rem;
}